#root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginContainer {
  width: 400px;
  height: 400px;
  padding: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: aquamarine;
}

.inputContainer { 
  padding-top: 8px;
  padding-bottom: 8px;
}

.inputLabel {
  padding-right: 8px;
}

.loginButton {
  background-color: #03a8c6;
}
